import { render, staticRenderFns } from "./AboutBook.vue?vue&type=template&id=481d83ab&scoped=true&"
import script from "./AboutBook.vue?vue&type=script&lang=js&"
export * from "./AboutBook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481d83ab",
  null
  
)

export default component.exports